* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  background-color: #101427;
  color: #e2e2e2;
  overflow-x: hidden;

  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
}

h1, h4 {
  margin: .5em;
}